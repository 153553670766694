<template>
  <div class="work-page nike-mercurial">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/NM_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">NIKE</p>
  			<a class="project">BORN MERCURIAL</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Fast by nature. Speed’s in their DNA.</h1>
  			</div>
  			<div class="col-2">
  				<p>A campaign for the Mercurial Vapor featuring Sam Kerr, Bruno Fornaroli & Mathew Leckie.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col">
      <div class="col" style="width: 45%; margin-right: -40px; z-index: 2;">
        <img src="/static/images/NM_2.jpg"/>
      </div>
      <div class="col" style="width: 60%">
        <img src="/static/images/NM_3.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="row" style="z-index: 2; position: relative;">
      <img src="/static/images/NM_4.jpg" style="max-width: 23%; float: right; margin-right: 18%; margin-top: -20%;"/>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/NM_5.jpg" style="margin-top: 8%;"/>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 65%; margin-top: -25%;">
        <img src="/static/images/NM_6.jpg" style="max-width: 70%; float: right; margin-top: -30%;"/>
      </div>
      <div class="col" style="width: 25%; margin-top: 35%; z-index: 3; position: relative;">
         <img src="/static/images/NM_7.jpg"/>
      </div>
    </div>

    <img src="/static/images/NM_8.jpg" style="width: 90%; margin-top: -10%;"/>

    <div class="footer">
      <div class="prev">
        <router-link to="/ca-watch-me">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/nike-play-gold">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'nike-mercurial',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.nike-mercurial {

/*  .copy container {
    .col-1 {
      width: 55%;
    }
  }
*/
  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

}

</style>
